import React from "react";
import { WmkSeo } from "wmk-seo";
// import { WmkSeo } from "wmk-lib";
import Layout from "../components/layout/MainLayout";
import { baseUrl, siteTitle, twitter } from "../vars/ThemeOptions";

const NotFoundPage = () => (
  <Layout>
    <WmkSeo.Meta
      title={"404 Page Not Found"}
      path={"/"}
      slug={"404"}
      ogImageUrl={""}
      twitterImageUrl={""}
      twitterHandle={twitter}
      siteTitle={siteTitle}
      baseUrl={baseUrl}
    /> 
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
